import axios from "axios"

export class API {
  constructor() {
    let options = {}
    if (process.env.VUE_APP_BACKEND_BASE_URL) {
      options.baseURL = process.env.VUE_APP_BACKEND_BASE_URL
    } else if (process.env.NODE_ENV === "development") {
      const dev_port = process.env.VUE_APP_BACKEND_PORT || 5000
      options.baseURL = `http://localhost:${dev_port}`
    }
    this.$http = axios.create(options);
  }
}


export const api = new API()
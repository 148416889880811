<template>
  <div>
    <div class="p-3"></div>

    <router-link
      :to="{ name: 'Home' }"
      class="btn bg-primary"
    >
      ← Back to overview
    </router-link>

    <div class="p-3"></div>

    <div v-if="state == 'loading'">Loading...</div>
    <div v-if="state == 'success'">
      <h1>{{ev.title}} <ev-status-widget :id="ev.id"></ev-status-widget></h1>

      <h3>Basic details</h3>
      <table class="table">
        <tbody>
          <tr>
            <th scope="row">Charging:</th>
            <td>(See icon by EV title)</td>
          </tr>
          <tr>
            <th scope="row">Charger type:</th>
            <td>{{ ev.charger_type }}</td>
          </tr>
        </tbody>
      </table>

      <div v-if="ev.charger_type == 'tplink'">
        <h3>tplink details</h3>
        <table class="table table-striped">
          <tbody>
            <tr>
              <th scope="row">IP:</th>
              <td v-if="ev.charger_options.ip">{{ ev.charger_options.ip }}</td>
              <td v-else><i>Unset or using global config</i></td>
            </tr>
            <tr>
              <th scope="row">Username:</th>
              <td v-if="ev.charger_options.username">{{ ev.charger_options.username }}</td>
              <td v-else><i>Unset or using global config</i></td>
            </tr>
            <tr>
              <th scope="row">Password:</th>
              <td v-if="ev.charger_options.password"><secret-value :value="ev.charger_options.password"></secret-value></td>
              <td v-else><i>Unset or using global config</i></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="p-3"></div>

      <div class="d-grid">
          <a
            :href="href"
            class="btn btn-danger text-center"
            @click="remove_ev"
          >
            Delete
          </a>
      </div>
    </div>
    <div v-if="state == 'error'">
      Error!

      <div class="d-grid">
        <a
          :href="href"
          class="btn btn-danger text-center"
          @click="remove_ev"
        >
          Delete
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {api} from "../api"

import EVStatusWidget from "./EVStatusWidget.vue"
import SecretValue from "./SecretValue.vue"

export default {
  name: "ViewEV",
  data: function () {
    return {
      ev: null,
      state: 'loading'
    }
  },
  methods: {
    load_ev_data: function(ev_id) {
      api.$http.get(`/api/evs/${ev_id}`).then((response) => {
        return response.data.ev
      }).then((ev) => {
        if (!ev.title) {
          ev.title = "Untitled EV"
        }

        this.ev = ev
        this.state = "success"
      }).catch(() => {
        this.state = "error"
      })
    },
    remove_ev: function() {
      const ev_id = this.$route.params.id
      api.$http.delete(`/api/evs/${ev_id}`).then((response) => {
        if (!response.data.success) {
          throw response.data
        }
        return true
      }).then(() => {
        this.$router.push({name: "Home"})
      })
    }
  },
  created: function() {
    const ev_id = this.$route.params.id
    this.load_ev_data(ev_id)
  },
  components: {
    "ev-status-widget": EVStatusWidget,
    "secret-value": SecretValue,
  }
}

</script>

<style scoped>
</style>
<template>
  <div>
    <div class="p-3"></div>

    <router-link
      :to="{ name: 'Home' }"
      class="btn bg-primary"
    >
      ← Back to overview
    </router-link>

    <div class="p-3"></div>

    <div>
      <h1>New EV</h1>

      <form
        @submit="submit"
      >
        <div class="mb-3">
          <label for="title" class="form-label">Title of EV</label>
          <input required v-model="title" type="text" class="form-control" id="title" aria-describedby="title-help">
          <div id="title-help" class="form-text">Give the EV a name.</div>
        </div>
        <div class="mb-3">
          <label for="title" class="form-label">How to control the charging</label>
          <select required v-model="charger_type" class="form-select">
            <option value="tplink">tplink P100</option>
          </select>
        </div>

        <div v-if="charger_type == 'tplink'">
          <h5>tplink P100 settings</h5>

          <div class="mb-3">
            <label for="charger_type_tplink_ip" class="form-label">IP</label>
            <input v-model="charger_options.ip" type="text" class="form-control" id="charger_type_tplink_ip" placeholder="192.168.1.123">
          </div>

          <div class="mb-3">
            <label for="charger_type_tplink_username" class="form-label">Username</label>
            <input v-model="charger_options.username" type="text" class="form-control" id="charger_type_tplink_username" placeholder="Username">
          </div>

          <div class="mb-3">
            <label for="charger_type_tplink_password" class="form-label">Password</label>
            <input v-model="charger_options.password" type="password" class="form-control" id="charger_type_tplink_password" placeholder="Password">
          </div>
        </div>

        <div v-if="state == 'error'" class="alert alert-danger" role="alert">
          {{ error_message }}
        </div>

        <div v-if="state == 'submitting'">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import {api} from "../api"

export default {
  name: "AddEV",
  data: function () {
    return {
      title: "",
      charger_type: "tplink",
      charger_options: {},
      state: "ready",
      error_message: "",
    }
  },
  methods: {
    submit: function(e) {
      console.log(this.title)
      console.log(this.charger_type)
      console.log(this.charger_options)

      let ev_data = {
        title: this.title,
        charger_type: this.charger_type,
        charger_options: null,
      }

      if (this.charger_type === "tplink") {
        ev_data.charger_options = {
          ip: this.charger_options.ip,
          username: this.charger_options.username,
          password: this.charger_options.password,
        }
      }


      this.state = "submitting"

      api.$http.post(`/api/evs`, {
        ev_data: ev_data,
      }).then((response) => {
        return response.data.id
      }).then((id) => {
        this.state = "success"
        this.$router.push({name: "ViewEV", params: {id: id}})
      }).catch((err) => {
        const message = err.response.data.message
        this.state = "error"
        this.error_message = `${err} - ${message}`
      })

      e.preventDefault()
    }
  }
}

</script>

<style scoped>
</style>
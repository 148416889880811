<template>
  <span :class="['badge', 'badge-pill', badgeClass]" @click="toggle_state">
    <template v-if="state == 'loading'">...</template>
    <template v-if="state == 'on'">On</template>
    <template v-if="state == 'off'">Off</template>
    <template v-if="state == 'unknown'">?</template>
  </span>
</template>

<script>
import { api } from "../api";

export default {
  name: "EVStatusWidget",
  props: {
    id: String,
  },
  data: function () {
    return {
      state: "loading"
    }
  },
  computed: {
    badgeClass: function() {
      switch (this.state) {
        case "on":
          return "bg-success"
        case "off":
          return "bg-danger"
        default:
          return "bg-secondary"
      }
    },
  },
  methods: {
    load_state: function() {
      const ev_id = this.id
      api.$http.get(`/api/evs/${ev_id}`).then((response) => {
        return response.data.ev
      }).then((ev) => {
        this.state = ev.charging ? "on" : "off"
      }).catch(() => {
        this.state = "unknown"
      })
    },
    toggle_state: function() {
      const ev_id = this.id
      if (this.state == 'on') {
        api.$http.get(`/api/evs/${ev_id}/rpc:stop`).then(() => {
          this.load_state()
        })
      } else if (this.state == 'off') {
        api.$http.get(`/api/evs/${ev_id}/rpc:start`).then(() => {
          this.load_state()
        })
      }
    },
  },
  created: function() {
    this.load_state()
  }
};
</script>

<style scoped>
span {
  cursor: pointer;
}
</style>
<template>
  <span>{{ displayedValue }} (<a href="#" @click="toggle">{{toggleValue}}</a>)</span>
</template>

<script>
export default {
  name: "SecretValue",
  props: {
    value: String
  },
  data: function() {
    return {
      shown: false,
    }
  },
  computed: {
    displayedValue: function() {
      if (this.shown) {
        return this.value
      } else {
        return "*".repeat(this.value.length)
      }
    },
    toggleValue: function() {
      if (this.shown) {
        return "hide"
      } else {
        return "show"
      }
    }
  },
  methods: {
    toggle: function(e) {
      this.shown = !this.shown
      e.preventDefault()
    },
  }
}
</script>

<style>

</style>
<template>
  <div>
    <h1>EVs</h1>
    <div class="list-group" v-if="evs.length > 0">
      <router-link
        v-for="ev in evs"
        :key="ev.id"
        custom
        :to="{ name: 'ViewEV', params: { id: ev.id }}"
        v-slot="{ href, navigate }"
      >
        <a
          :href="href"
          @click="navigate"
          class="list-group-item list-group-item-action"
        >
          {{ ev.title }}

          <ev-status-widget :id="ev.id"></ev-status-widget>
        </a>
      </router-link>

    </div>
    <div v-else><i>There are no EV currently. Add one on the plus.</i></div>

    <div class="p-3"></div>

    <div class="d-grid">
      <router-link
        :to="{ name: 'AddEV' }"
        class="btn btn-primary text-center"
      >
        +
      </router-link>
    </div>
  </div>
</template>

<script>

import {api} from "../api"

import EVStatusWidget from "./EVStatusWidget.vue"

export default {
  name: "Home",
  data: function () {
    return {
      evs: []
    };
  },
  methods: {
    get_evs: function() {
      return api.$http.get("/api/evs").then((response) => {
        return response.data.evs
      })
    }
  },
  created: function() {
    this.get_evs().then((evs) => {
      this.evs = evs
    })
  },
  components: {
    "ev-status-widget": EVStatusWidget,
  }
};
</script>

<style scoped>
</style>
import { createWebHistory, createRouter } from "vue-router";

import NotFound from "./components/NotFound.vue";
import Home from "./components/Home.vue";
import ViewEV from "./components/ViewEV.vue"
import AddEV from "./components/AddEV.vue"

const routes = [
    { path: "/", name: "Home", component: Home },
    { path: "/add-ev", name: "AddEV", component: AddEV },
    { path: "/evs/:id", name: "ViewEV", component: ViewEV },
    { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
]

export const router = createRouter({
    history: createWebHistory(),
    routes: routes,
})